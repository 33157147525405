<template>
  <div style="width: 100%">
    <!-- <input
      style="display: none"
      id="files"
      ref="files"
      @change="fileImg"
      type="file"
    /> -->
    <ckeditor
     class="ckeditor"
      :editor="editor"
      v-model="content"
      @input="editorChange"
      @ready="onReady"
      :config="editorConfig"
    ></ckeditor>
  </div>
</template>


<script>
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import '@ckeditor/ckeditor5-build-classic/build/translations/zh-cn.js'
let that
export default {
  props: ["contents"],
  components: {
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      editor: ClassicEditor,
      allArr: [],
      delArr: [],
      quill: "",
      content: this.contents,
      editorConfig: {
        language: 'zh-cn', 
      },
    };
  },
  mounted() {
    that=this;
    let arr = [];
    this.content.replace(
        /<img[^>]*src=['"]([^'"]+)[^>]/g,
        (match, capture) => {
          //  let imgArr= capture.split("/")
          //  let item=imgArr[imgArr.length-1];
          arr.push(capture);
          let a = true;
          this.allArr.forEach((element) => {
            if (element == capture) {
              a = false;
            }
          });
          if (a) {
            this.allArr.push(capture);
          }
        }
      );
    
  },
  methods: {
    editorChange(value){
      let arr = [];
      this.content.replace(
        /<img[^>]*src=['"]([^'"]+)[^>]/g,
        (match, capture) => {
          //  let imgArr= capture.split("/")
          //  let item=imgArr[imgArr.length-1];
          arr.push(capture);
          let a = true;
          this.allArr.forEach((element) => {
            if (element == capture) {
              a = false;
            }
          });
          if (a) {
            this.allArr.push(capture);
          }
        }
      );
      // console.log(this.allArr);
      this.allArr.forEach((element) => {
        let flag = true;
        arr.forEach((ele) => {
          if (element == ele) {
            flag = false;
          }
        });
        if (flag && this.delArr.indexOf(element) == "-1") {
          this.delArr.push(element);
        }
      });
      this.$emit("changeDel", this.delArr);
      this.$emit("changeContent", this.content);
      this.$emit("changeAllArr", this.allArr);
    },

    onReady(editor) {
      
      document.getElementsByClassName("ck-balloon-panel")[0].style.zIndex="9999"
     editor.updateSourceElement(that.content);
      class MyUploadAdapter {
        constructor(loader) {
          // The file loader instance to use during the upload.
          this.loader = loader;
        }

        // Starts the upload process.
       async upload() {
          
            let form = new FormData();
            let files=await this.loader.file;
            if (files.size/1024/1024>1) {
              that.$message({
          type: "warning",
          message: "上传的图片不能超过1M！",
        });
        this.loader=null;
        return ;
            }
            form.append("file", await this.loader.file);
            const url = await that.$api.alarmSolution.uploadImg(form)
          return {
                  default: url.data[0],
              };
      
    
        
        }
        abort() {
          server.abortUpload();
        }
      }
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader);
      };
    },
  },
};
</script>
<style scoped>
::v-deep .ck-editor__editable_inline {
  height: 400px;
}
/* div{
  color: black;
} */
</style>