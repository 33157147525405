<template>
  <div class="iot-task iot-search-header">
    <!-- <div slot="header">
      <el-form :inline="true" :model="getForm">
        <el-button
          type="primary"
          plain
          size="mini"
          class="iot-task-create"
          v-show="$store.state.permissionArr.indexOf('timer:add') > -1"
          @click="addTask()"
          >新增</el-button
        >
      </el-form>
    </div> -->
    <el-table
      :data="tableData"
      stripe
      border=""
      style="width: 100%"
      :header-cell-style="{ background: theme.TABLE_HEADER_COLOR }"
    >
      <el-table-column prop="timerName" label="任务名称"> </el-table-column>
      <el-table-column prop="gatewayName" label="所属网关"></el-table-column>
      <el-table-column prop="paramName" label="参数名称"></el-table-column>
      <el-table-column prop="sendValue" label="下发值"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="success"
            plain
            v-show="$store.state.permissionArr.indexOf('timer:update') > -1"
            @click="editTask(scope.row.timerId)"
            >修 改</el-button
          >
          <el-button
            type="danger"
            plain
            size="mini"
            @click="del(scope.row.timerId)"
            v-show="$store.state.permissionArr.indexOf('timer:delete') > -1"
            >删 除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="getForm.current"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="getForm.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      style="text-align: right; margin-top: 10px; margin-right: 10px"
    ></el-pagination>
    <el-dialog
      :title="formTitle"
      :show-close="false"
      :visible.sync="dialogVisinbile"
      :before-close="handleClose"
      :destroy-on-close="true"
      :close-on-press-escape="true"
      :close-on-click-modal="false"
      top="3%"
      append-to-body
      class="timed-task-dialog"
      width="33%"
    >
      <el-form
        :model="taskForm"
        ref="taskRef"
        label-width="140px"
        :rules="rules"
      >
        <el-form-item label="定时任务名称" prop="timerName">
          <el-input
            v-model="taskForm.timerName"
            placeholder="请输入定时任务名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="网关名称" prop="gatewayId">
          <el-select
            v-model="taskForm.gatewayId"
            filterable
            placeholder="请选择网关"
            @change="changeGateway"
          >
            <el-option
              v-for="item in gatewayArr"
              :key="item.gatewayId"
              :label="item.gatewayName"
              :value="item.gatewayId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备名称" prop="subOriginalId">
          <el-select
            v-model="taskForm.subOriginalId"
            filterable
            placeholder="请选择设备"
            @change="changeOriginal"
          >
            <el-option
              v-for="item in originalArr"
              :key="item.subOriginalId"
              :label="item.subOriginalName"
              :value="item.subOriginalId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="参数名称" prop="paramId">
          <el-select
            v-model="taskForm.paramId"
            filterable
            placeholder="请选择参数"
          >
            <el-option
              v-for="item in paramArr"
              :key="item.paramId"
              :label="item.paramName"
              :value="item.paramId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间设定">
          <cron @cronCreate="cronCreate"></cron>
        </el-form-item>
        <el-form-item label="下发值">
          <el-input-number
            size="small"
            v-model="taskForm.sendValue"
            autocomplete="off"
            :min="0"
            :max="99999999"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
         <el-button type="primary" plain size="mini" @click="submitForm"
          >确 定</el-button>
        <el-button
          type="info"
          plain
          size="mini"
          @click="dialogVisinbile = false"
          >取 消</el-button
        >
       
        
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { TABLE_HEADER_COLOR } from "../util/theme";
import { analysis } from "../util/cron";
import cron from "../components/cron";
import reg from "../util/regular";
let that;
export default {
  name: "TimeTask",
  props: {
    currentPlanId: {
      type: Number,
      required: true,
    },
  },
  components: { cron },
  data() {
    return {
      theme: { TABLE_HEADER_COLOR },
      analysis: analysis,
      dialogVisinbile: false,
      tableData: [],
      getForm: {
        size: 10,
        current: 1,
        planId: "",
      },
      total: 0,
      taskForm: {
        timerId: "",
        timerName: "",
        planId: "",
        paramId: "",
        subOriginalId: "",
        gatewayId: "",
        cronValue: "* * * * * *",
        perType: "",
        timeType: "",
        sendValue: 0,
      },
      rules: {
        timerName: [
          { required: true, message: "请输入定时任务名称", trigger: "blur" },
          {
            pattern: reg.nameR,
            message: "最长支持15个字符",
          },
        ],
        gatewayId: [
          { required: true, message: "请选择网关名称", trigger: "blur" },
        ],
        subOriginalId: [
          { required: true, message: "请选择设备名称", trigger: "blur" },
        ],
        paramId: [
          { required: true, message: "请选择参数名称", trigger: "blur" },
        ],
      },
      paramArr: [],
      originalArr: [],
      gatewayArr: [],
      isCreate: true,
      formTitle: "",
    };
  },
  methods: {
    handleSizeChange(val) {
      that.$set(that.getForm, "size", val);
      that.$set(that.getForm, "current", 1);
      that.getTaskPage();
    },
    handleCurrentChange(val) {
      that.$set(that.getForm, "current", val);
      that.getTaskPage();
    },
    getTaskPage() {
      that.$api.plan.getTaskPage(that.getForm).then((response) => {
        that.tableData = response.data.records;
        that.total = response.data.total;
      });
    },
    getGatewayArr() {
      that.$api.plan.getGatewayArr(that.currentPlanId).then((response) => {
        if (response.code == 200) {
          that.gatewayArr = response.data;
        }
      });
    },
    getOriginalArr(v) {
      that.$api.plan.getOriginalArr(v).then((response) => {
        if (response.code == 200) {
          that.originalArr = response.data;
        }
      });
    },
    getParamArr(v) {
      that.$api.parameter.getParamArr(v).then((response) => {
        if (response.code == 200) {
          that.paramArr = response.data;
        }
      });
    },
    changeGateway(v) {
      that.getOriginalArr(v, true);
    },
    changeOriginal(v) {
      that.getParamArr(v, true);
    },
    addTask() {
      that.dialogVisinbile = true;
      that.isCreate = true;
      that.formTitle = "新增定时任务";
      that.getGatewayArr();
    },
    editTask(id) {
      that.$api.plan.getTask(id).then((response) => {
        if (response.code == 200) {
          that.taskForm = response.data;
          that.dialogVisinbile = true;
          that.isCreate = false;
          that.formTitle = "修改定时任务";
          that.getOriginalArr(that.taskForm.gatewayId, true);
          that.getParamArr(that.taskForm.subOriginalId, true);
        }
      });
    },
    submitForm() {
      that.$refs["taskRef"].validate((valid) => {
        if (valid) {
          let _api = that.isCreate
            ? that.$api.plan.addTask(that.taskForm)
            : that.$api.plan.editTask(that.taskForm);
          _api.then((response) => {
            if (response.code == 200) {
              that.dialogVisinbile = false;
              that.isCreate = true;
              that.getTaskPage();
              that.$refs.taskRef.resetFields();
              that.$message({
                type: "success",
                message: '请进行远程更新项目！',
                showClose: true,
                 center: true,
              });
            }
          });
        }
      });
    },
    del(id) {
      that
        .$confirm("确认删除", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that.$api.plan.delTask(id).then((response) => {
            if (response.code == 200) {
              that.$message({
                type: "success",
                message: response.msg,
              });
              that.getTaskPage();
            }
          });
        });
    },
    handleClose() {
      that.dialogVisinbile = false;
    },
    cronCreate(data) {
      let curD = that.taskForm;
      curD = Object.assign({}, curD, data);
      that.taskForm = curD;
    },
  },
  beforeCreate() {
    that = this;
  },
  mounted() {
    that.$set(that.getForm, "planId", that.currentPlanId);
    that.$set(that.taskForm, "planId", that.currentPlanId);
    that.getTaskPage();
  },
};
</script>

<style scoped>
.el-select,
.el-input,
.el-input-number {
  width: 250px;
}
.iot-search-header >>> .el-card__header {
  padding: 0 18px;
}
.timed-task-dialog >>> .el-dialog {
  background-color: #f5f7fa;
  height: 775px;
}
.el-input-number >>> input {
  text-align: left;
}
.iot-task {
  width: 96%;
  padding: 0 2%;
}
.iot-task-create {
  float: right;
  margin: 10px;
}
.timed-task-but {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog-footer {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
</style>
