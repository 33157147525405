<template>
  <div class="iot-trigger iot-search-header">
    <el-table
      :data="tableData"
      stripe
      border=""
      style="width: 100%"
      :header-cell-style="{ background: theme.TABLE_HEADER_COLOR }"
    >
      <el-table-column prop="dataApplicationId" label="数据应用ID"></el-table-column>
      <el-table-column prop="dataApplicationName" label="数据应用名称"></el-table-column>
      <el-table-column prop="dataConditionName" label="数据条件"></el-table-column>
      <el-table-column prop="paramName" label="参数"></el-table-column>
      <el-table-column prop="paramTypeName" label="参数类型"></el-table-column>
      <el-table-column prop="typeValue" label="保留小数位"></el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            plain
            v-show="$store.state.permissionArr.indexOf('dataApplication:update') > -1"
            @click="editApplication(scope.row.dataApplicationId)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="danger"
            plain
            v-show="$store.state.permissionArr.indexOf('dataApplication:delete') > -1"
            @click="del(scope.row.dataApplicationId)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="getForm.current"
      :page-count="pages"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="getForm.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      style="text-align: right; margin-top: 10px; margin-right: 10px"
    ></el-pagination>

    <!-- 数据应用新增修改 -->
    <el-dialog
    top="3%"
      class="trigger-dialog"
      :show-close="false"
      :visible.sync="dialogVisinbile"
      :destroy-on-close="true"
      :close-on-press-escape="true"
      :close-on-click-modal="false"
      append-to-body
      width="35%"
     
    >
    <div slot="title" style="">
        <img
          style="width: 25px; vertical-align: middle; margin-top: -5px"
          src="../assets/pencil.png"
        />
        <div style="margin-left: 7px; padding-top: 15px; display: inline-block">
          {{ formTitle }}
        </div>
      </div>
      <el-form
        style="padding:50px 100px;height:50px"
        :model="application"
        ref="applicationRef"
        label-width="140px"
        :rules="rules"
      >
        <div class="trigger-title">基础信息</div>
        <el-form-item label="数据应用名称" prop="dataApplicationName">
          <el-input
            v-model="application.dataApplicationName"
            placeholder="请输入数据应用名称"
          ></el-input>
        </el-form-item>
      
          <el-form-item label="选择参数" prop="gatewayId">
          <el-cascader
           v-if="planList.length!=0"
            class="lzlinks-cascader"
            :props="props"
            :options="planList"
            @change="cascaderChange"
            v-model="selectList"
            
          ></el-cascader>
        </el-form-item>

         <!-- <el-form-item label="参数类型" >
          <el-radio-group readonly v-model="application.paramTypeId" >
            <el-radio :label="1"  >数值</el-radio>
            <el-radio :label="3"  >整数型浮点</el-radio>
          </el-radio-group> 
        </el-form-item> -->

        <el-form-item label="保留小数位" v-if="application.paramTypeId==3" >
          <el-input
            v-model="application.typeValue"
            placeholder="请输入保留小数位"
          ></el-input>
        </el-form-item>
        <el-form-item label="数据条件" prop="paramId">
          <el-select
            v-model="application.dataConditionId"
            filterable
            placeholder="请选择数据条件"
          >
            <el-option
              v-for="item in dataCondition"
              :key="item.dataConditionId"
              :label="item.dataConditionName"
              :value="item.dataConditionId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-divider /> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain size="mini"  @click="sumbitCondition"
          >确 定</el-button
        >
        <el-button
          type="info"
          plain
          size="mini"
          @click="dialogVisinbile = false"
          >取 消</el-button
        >
       
      </div>
    </el-dialog>
  </div>
</template>
<script>

import { TABLE_HEADER_COLOR } from "../util/theme";
import reg from "../util/regular";
let that;
export default {
  name: "Trigger",
  props: {
    currentPlanId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      props: {
        //下拉联动
        lazy: true,
        value: "value",
        label: "label",
        multiple: false,
        lazyLoad: this.lazyLoad,
      },
      selectList: [],
      theme: { TABLE_HEADER_COLOR },
      dialogVisinbile: false,
      tableData: [],
      planList: [],
      originalArr: [],
      getForm: {
        dataApplicationName: "",
        size: 10,
        current: 1,
        planId: "",
      },
      paramArr: [],
      dataCondition:[],
      paramTypeArr:[],
     pages:1,
      total: 0,
      application: {
        planId: "",
        dataApplicationName: "",
        paramId: "",
        subOriginalId: "",
        gatewayId: "",
        
        typeValue:0,
      },
      permissionForm: {},
      formTitle: "",
      isCreate: true,

      rules: {
        dataApplicationName: [
          { required: true, message: "请输入触发器名称", trigger: "blur" },
          {
            pattern: reg.nameR,
            message: "最长支持15个字符",
          },
        ],
        typeValue: [
          { required: true, message: "请输入类型值", trigger: "blur" },
        ],
        gatewayId: [{ required: true, message: "请选择网关", trigger: "blur" }],
        subOriginalId: [
          { required: true, message: "请选择原件", trigger: "blur" },
        ],
        paramId: [{ required: true, message: "请选择参数", trigger: "blur" }],
        conditionId: [
          { required: true, message: "请选择触发条件", trigger: "blur" },
        ],
        aValue: [{ required: true, message: "请选择阈值A", trigger: "blur" }],
        bValue: [{ required: true, message: "请选择阈值B", trigger: "blur" }],
        "alarmForward.gatewayId": [
          {
            validator: (rule, value, callback) => {
              if (
                that.application.forward == 1 &&
                that.application.alarmForward.gatewayId == ""
              ) {
                callback(new Error("请选择转发网关"));
              }
              callback();
            },
            trigger: "blur",
          },
        ],
        "alarmForward.subOriginalId": [
          {
            validator: (rule, value, callback) => {
              if (
                that.application.forward == 1 &&
                that.application.alarmForward.subOriginalId == ""
              ) {
                callback(new Error("请选择转发原件"));
              }
              callback();
            },
            trigger: "blur",
          },
        ],
        "alarmForward.paramId": [
          {
            validator: (rule, value, callback) => {
              if (
                that.application.forward == 1 &&
                that.application.alarmForward.paramId == ""
              ) {
                callback(new Error("请选择转发参数"));
              }
              callback();
            },
            trigger: "blur",
          },
        ],
        "alarmForward.sendData": [
          {
            validator: (rule, value, callback) => {
              if (
                that.application.forward == 1 &&
                (that.application.alarmForward.sendData == "" ||
                  that.application.alarmForward.sendData == undefined)
              ) {
                callback(new Error("请选择转发值"));
              }
              callback();
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    
    lazyLoad(node, resolve) {
     
      if (node.level == 1) {
        that.changeGateway(node.data.value);
      } else if (node.level == 2) {
      
          that.changeOriginal(node.data.value);
        
      }
      if (node.level != 0) {
        setTimeout(() => {
        let nodes = [];
        if (node.level == 1) {
          nodes = that.originalArr;
        } else if (node.level == 2) {
            nodes = that.paramArr;
          
        }
        // 通过调用resolve将子节点数据返回，通知组件数据加载完成
        resolve(nodes); 
        }, 1000);
      }
      
     
    },
    cascaderChange: function (val) {
      that.$set(that.application, "gatewayId", val[0]);
      that.$set(that.application, "subOriginalId", val[1]);
      that.$set(that.application, "paramId", val[2]);
    },
    // radiochange(val){
    //   console.log(val);
    // },
  handleSizeChange(val) {
      that.$set(that.getForm, "size", val);
      that.$set(that.getForm, "current", 1);
      that.getListData();
    },
    handleCurrentChange(val) {
      that.$set(that.getForm, "current", val);
      that.getListData();
    },

    getParamTypeArr() {
      that.$api.parameter.getParamType().then((response) => {
        if (response.code == 200) {
          // console.log(response.data);
          that.paramTypeArr = response.data;
          // if (that.isCreateParam == true) {
          //   that.$set(
          //     that.paramForm,
          //     "paramTypeId",
          //     that.paramTypeArr[0].paramTypeId
          //   );
          //   that.$set(
          //     that.paramForm,
          //     "dataAttr",
          //     that.constant.dataAttr[0].key
          //   );
          // }
        }
      });
    },
    getListData(){
      that.$api.dataApplication.getListData(that.getForm).then(res=>{
        if(res.code==200)
        that.tableData=res.data.records;
        that.total=res.data.total;
        that.pages=res.data.pages;
      })
    },
    editApplication(data){
      
      that.$api.dataApplication.getdataApplication(data).then(res=>{
        if(res.code==200){
        that.selectList=[];
        that.formTitle = "修改数据应用";
        that.application=res.data;
        that.dialogVisinbile = true;  
        that.editGatewayArr1(res.data)
        that.selectList=[res.data.gatewayId,res.data.subOriginalId,res.data.paramId];
        that.getDataConditionList();
        that.isCreate=false;
        }
      })
    },
   editGatewayArr1 (v) {
     that.planList=[];
      that.$api.plan.getGatewayArr(that.currentPlanId).then((response) => {
        if (response.code == 200) {
          
          let newOpts = [];
          response.data.forEach((element) => {
            newOpts.push({
              value: element.gatewayId,
              label: element.gatewayName,
            });
          });

          that.$api.plan.getOriginalArr(v.gatewayId).then((response) => {
            if (response.code == 200) {
              let newOpts1 = [];
              response.data.forEach((element) => {
                newOpts1.push({
                  value: element.subOriginalId,
                  label: element.subOriginalName,
                });
              });

              that.$api.parameter.getParamArr(v.subOriginalId).then((res) => {
                if (res.code == 200) {
                  that.paramArr2 = {};
                  let newOpts2 = [];
                  res.data.forEach((element) => {
                    newOpts2.push({
                      value: element.paramId,
                      label: element.paramName,
                      leaf: true,
                    });
                  });

                  for (let i = 0; i < newOpts1.length; i++) {
                    if (newOpts1[i].value == v.subOriginalId) {
                      that.$set(newOpts1[i], "children", newOpts2);
                    }
                  }
                  for (let i = 0; i < newOpts.length; i++) {
                    if (newOpts[i].value == v.gatewayId) {
                      that.$set(newOpts[i], "children", newOpts1);
                    }
                  }
                 
                  that.planList = newOpts;
                }
              });
            }
          });
        }
      });
    },
     getPlanList(){
       that.$api.gateway.getGatewayList(that.currentPlanId).then(res=>{
          if(res.code==200){
            let newOpts = [];
          res.data.forEach((element) => {
            newOpts.push({
              value: element.gatewayId,
              label: element.gatewayName,
            });
          });
         that.planList=newOpts;
        
         }
       })
     },
     getDataConditionList(){
       that.$api.dataCondition.getDataConditionList().then(res=>{
          if(res.code==200){
         that.dataCondition=res.data
       
         }
       })
     },
     changeGateway(data){
       
        that.$api.plan.getOriginalArr(data).then(response=>{
           if (response.code == 200) {
             that.originalArr=[];
               let newOpts = [];
          response.data.forEach((element) => {
            newOpts.push({
              value: element.subOriginalId,
              label: element.subOriginalName,
            });
          });
         that.originalArr=newOpts
         
           }
       })
     },
     sumbitCondition(){
      that.application.typeValue=that.application.typeValue*1
      if (that.application.paramTypeId==1) {
        that.application.typeValue=0
      }
      
         that.$refs["applicationRef"].validate((valid) => {
        if (valid) {
          let _api = that.isCreate
            ? that.$api.dataApplication.adddataApplication(that.application)
            : that.$api.dataApplication.editdataApplication(that.application);
          _api.then((response) => {
            if (response.code == 200) {
              that.dialogVisinbile = false;
              that.isCreate = true;
              that.$refs.applicationRef.resetFields();
              that.getListData();
              
            }
          });
        }
      });
     },
     changeOriginal(data){
      
        that.$api.parameter.getParamArr(data).then((response) => {
        if (response.code == 200) {
             let newOpts = [];
          response.data.forEach((element) => {
            newOpts.push({
              value: element.paramId,
              label: element.paramName,
              leaf: true,
            });
          });
          that.paramArr = newOpts;
        
        }
      });
      
     },
    addapplication() {
      that.selectList=[];
      that.planList=[];
      that.application={
        planId: that.currentPlanId,
        dataApplicationName: "",
        paramId: "",
        subOriginalId: "",
        gatewayId: "",
        paramTypeId:1,
        typeValue:0,
      },
      that.getPlanList();
      that.getDataConditionList();
      that.dialogVisinbile = true;
      that.isCreate = true;
      that.formTitle = "新增数据应用";
      that.getListData();
    },
    
    del(triggerId) {
      that
        .$confirm("确认删除", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that.$api.dataApplication.deldataApplication(triggerId).then((response) => {
            if (response.code == 200) {
              that.$message({
                type: "success",
                message: response.msg,
              });
              that.getListData();
            }
          });
        })
        .catch(() => {});
    },
  },
  beforeCreate() {
    that = this;
  },
  mounted() {
   that.getParamTypeArr();
    that.$set(that.getForm, "planId", that.currentPlanId);
    that.$set(that.application, "planId", that.currentPlanId);
    that.getListData();
    that.getDataConditionList();
    
  },
};
</script>

<style scoped>
.el-select,
.el-input,
.el-input-number {
  width: 250px;
}
.el-input-number >>> input {
  text-align: left;
}
.iot-trigger {
  width: 96%;
  padding: 0 2%;
}
.iot-trigger-create {
  float: right;
  margin: 10px;
}
.iot-trigger-permission {
  height: 387px;
}
.iot-search-header >>> .el-card__header {
  padding: 0 18px;
}

.trigger-dialog >>> .el-dialog__body {
  height: 387px;
  overflow-y: auto;
}
.trigger-dialog >>> .el-dialog__body::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.trigger-dialog >>> .el-dialog {
  background-color: #f5f7fa;
  height: 775px;
}
.trigger-dialog >>> .el-tabs__nav-wrap::after {
  background-color: rgba(0, 0, 0, 0);
}
.trigger-title {
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 800;
}
.trigger-but {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog-footer {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
</style>