/**
 * cron转化工具
 */

//秒定时
const typ1Deal = function (data) {
    let secondStr = data[0];
    return secondStr.split('/')[1];
}

//分钟定时
const typ2Deal = function (data) {
    let minuteStr = data[1];
    return minuteStr.split('/')[1];
}

//小时定时
const typ3Deal = function (data, timeType) {
    let hourBeginStr = data[2]('/')[0];
    let minuteBeginStr = data[1];
    let hourStr = timeType == 0 ? data[2]('/')[1] : "";
    return {
        hourBeginStr,
        minuteBeginStr,
        hourStr,
        timeType
    }
}

// 周定时
const typ4Deal = function (data) {
    let hourBeginStr = data[2];
    let minuteBeginStr = data[1];
    let week = data[5];
    return {
        hourBeginStr,
        minuteBeginStr,
        week
    }
}

// 日期定时
const typ5Deal = function (data) {
    let hourBeginStr = data[2];
    let minuteBeginStr = data[1];
    let day = data[3].split(',');
    return {
        hourBeginStr,
        minuteBeginStr,
        day
    }
}

export const analysis = function (cronValue, perType, timeType) {
    let cronArr = cronValue.split(" ");
    switch (perType) {
        case 1:
            typ1Deal(cronArr);
            break;
        case 2:
            typ2Deal(cronArr);
            break;
        case 3:
            typ3Deal(cronArr, timeType);
            break;
        case 4:
            typ4Deal(cronArr);
            break;
        case 5:
            typ5Deal(cronArr);
            break;
        default:
            break;
    }


};

export default {
    analysis
}


