<template>
  <div class="iot-base public-scroll-transparent">
    <el-tabs value="1">
      <el-tab-pane label="基础设置" name="1">
        <el-form
          class="plan-info-form"
          ref="planRef"
          :model="form"
          label-width="150px"
          :rules="rules"
        >
          <el-form-item label="方案名称：" prop="planName" label-width="150px">
            <el-input v-model="form.planName" style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item label="移动端组态方案：" label-width="150px">
      <el-radio-group v-model="form.showType">
         <el-radio :label="0">重新绘制竖屏方案</el-radio>
          <el-radio :label="1">电脑端方案横屏显示</el-radio>
    </el-radio-group>
          </el-form-item>
          <el-form-item label="方案图标：" prop="planImg" label-width="150px">
            <div
              class="iot-radio-item"
              v-for="item in planImgList"
              :key="item.planImgId"
              :label="item.planImgId"
              @click="$set(form, 'planImg', item.imgName)"
              :style="{
                border:
                  item.imgName == form.planImg
                    ? '2px solid #409eff'
                    : '2px solid #b7b7b7',
              }"
            >
              <img
                style="
                  width: 35px;
                  height: 35px;
                  margin: 7.5px 12.5px 7.5px 12.5px;
                "
                :src="`${constant.OSS_URL}${constant.PLAN_IMG}${item.imgName}`"
              />
            </div>
            <!-- <el-radio
              v-model="form.planImg"
              v-for="item in constant.planImg"
              :key="item.key"
              :label="item.key"
              ><img
                style="width: 30px; height: 30px"
                :src="`${constant.OSS_URL}${constant.PLAN_IMG}${item.value}`"
            /></el-radio> -->
          </el-form-item>
          
        </el-form>
      </el-tab-pane></el-tabs
    >
  </div>
</template>
<script>
import { OSS_URL, PLAN_IMG, planImg } from "../util/constant";
import reg from "../util/regular";

let that;
export default {
  name: "PlanInfo",
  props: {
    currentPlanId: {
      type: Number,
      required: true,
    },
    editData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      constant: { OSS_URL, PLAN_IMG, planImg },
      form: {
        planId: "",
        planName: "",
        planImg: "消防.png",
        showType:0,
      },
      planImgList:[],
      isCreate: true,
      tableData: {},
      rules: {
        planName: [
          { required: true, message: "请选择方案名称", trigger: "blur" },
          {
            pattern: reg.nameR,
            message: "最长支持15个字符",
          },
        ],
      },
    };
  },
  methods: {
    addPlan() {
      that.isCreate = true;
      that.submitForm();
    },
    editPlan() {
      that.isCreate = false;
      that.submitForm();
    },
    submitForm() {
      that.$emit("subData", that.form);
    },
    getPlanImgList(){
      that.$api.plan.getPlanImg().then(res=>{
        if (res.code==200) {
          that.planImgList=res.data
        }
       
      })
    }
  },
  beforeMount() {
    that = this;
  },
  mounted() {
    that.getPlanImgList();
    if (Object.keys(that.editData).length > 0) {
      that.form = that.editData;
    }
  },
};
</script>

<style scoped>
.el-select,
.el-input,
.el-input-number {
  width: 250px;
}
.iot-base {
  width: 90%;
  height: 100%;
  margin-left: 2%;
}
.plan-info-form {
  padding: 10px 30px 0 0px;
}
.plan-but {
  display: flex;
  align-items: center;
  justify-content: center;
}
.iot-radio-item {
  display: inline-block;

  border-radius: 3px;
  width: 60px;
  height: 50px;
  margin-left: 10px;
  cursor: pointer;
}
</style>
