<template>
  <el-tabs v-model="activeName" class="iot-tabs-task">
    {{ cronValue }}
    <el-tab-pane label="秒" name="1">
      <el-form
        :model="form1"
        status-icon
        ref="form"
        label-width="100px"
        label-position="left"
      >
        <el-form-item label="周期（秒）" prop="seconds">
          <el-input-number
            size="small"
            v-model="form1.seconds"
            autocomplete="off"
            :min="1"
            :max="59"
            @change="secondsChange"
          ></el-input-number>
        </el-form-item>
      </el-form>
    </el-tab-pane>
    <el-tab-pane label="分钟" name="2">
      <el-form
        :model="form2"
        status-icon
        ref="form"
        label-width="100px"
        label-position="left"
      >
        <el-form-item label="周期（分）" prop="minutes">
          <el-input-number
            size="small"
            v-model="form2.minutes"
            autocomplete="off"
            :min="1"
            :max="59"
            @change="minutesChange"
          ></el-input-number>
        </el-form-item>
      </el-form>
    </el-tab-pane>
    <el-tab-pane label="小时" name="3">
      <el-form
        :model="form3"
        status-icon
        ref="form"
        label-width="100px"
        label-position="left"
      >
        <el-form-item label="时间类型" prop="type">
          <el-radio-group v-model="form3.type" @change="hoursChange">
            <el-radio :label="0">时间周期</el-radio>
            <el-radio :label="1">每日同时</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="起始小时" prop="beginHour">
          <el-input-number
            size="small"
            v-model="form3.beginHour"
            autocomplete="off"
            :min="1"
            :max="24"
            @change="hoursChange"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="起始分钟" prop="beginMinute">
          <el-input-number
            size="small"
            v-model="form3.beginMinute"
            autocomplete="off"
            :min="1"
            :max="59"
            @change="hoursChange"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="周期（小时）" prop="hours" v-if="form3.type == 0">
          <el-input-number
            size="small"
            v-model="form3.hours"
            autocomplete="off"
            :min="1"
            :max="24"
            @change="hoursChange"
          ></el-input-number>
        </el-form-item>
      </el-form>
    </el-tab-pane>
    <el-tab-pane label="周定时" name="4">
      <el-form
        :model="form4"
        status-icon
        ref="form"
        label-width="100px"
        label-position="left"
      >
        <el-form-item label="起始小时" prop="beginHour">
          <el-input-number
            size="small"
            v-model="form4.beginHour"
            autocomplete="off"
            :min="1"
            :max="24"
            @change="weekChange"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="起始分钟" prop="beginMinute">
          <el-input-number
            size="small"
            v-model="form4.beginMinute"
            autocomplete="off"
            :min="1"
            :max="59"
            @change="weekChange"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="周" prop="week">
          <el-radio-group v-model="form4.week" @change="weekChange">
            <el-radio v-for="item in 7" :label="item" :key="item">{{
              "周" + weekdata[item]
            }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </el-tab-pane>
    <el-tab-pane label="日期定时" name="5">
      <el-form
        :model="form5"
        status-icon
        ref="form"
        label-width="100px"
        label-position="left"
      >
        <el-form-item label="起始小时" prop="beginHour">
          <el-input-number
            size="small"
            v-model="form5.beginHour"
            autocomplete="off"
            :min="1"
            :max="24"
            @change="dayChange"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="起始分钟" prop="beginMinute">
          <el-input-number
            size="small"
            v-model="form5.beginMinute"
            autocomplete="off"
            :min="1"
            :max="59"
            @change="dayChange"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="日期" prop="day">
          <el-checkbox-group v-model="form5.day" @change="dayChange">
            <el-checkbox v-for="item in 31" :label="item" :key="item">{{
              item
            }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import { analysis } from "../util/cron";
let that;
export default {
  name: "Cron",
  data() {
    return {
      handleClick: "",
      cron: { analysis },
      weekdata: ["零", "一", "二", "三", "四", "五", "六", "七"],
      activeName: "1",
      form1: {
        seconds: 1,
      },
      form2: {
        minutes: 1,
      },
      form3: {
        hours: 1,
        type: 0,
        beginHour: 0,
        beginMinute: 0,
      },
      form4: {
        week: [1],
        beginHour: 0,
        beginMinute: 0,
      },
      form5: {
        day: [1],
        beginHour: 0,
        beginMinute: 0,
      },
      cronValue: "",
    };
  },
  methods: {
    secondsChange: function (v) {
      that.cronValue = "0/" + v + " * * * * *";
      that.$emit("cronCreate", {
        cronValue: that.cronValue,
        perType: 1,
        timeType: 0,
      });
    },
    minutesChange: function () {
      that.cronValue = "0 0/" + that.form2.minutes + " * * * *";
      that.$emit("cronCreate", {
        cronValue: that.cronValue,
        perType: 2,
        timeType: 0,
      });
    },
    hoursChange: function () {
      if (that.form3.type == 0) {
        that.cronValue =
          "0 " +
          that.form3.beginMinute +
          " " +
          that.form3.beginHour +
          "/" +
          that.form3.hours +
          " * * *";
        that.$emit("cronCreate", {
          cronValue: that.cronValue,
          perType: 3,
          timeType: 0,
        });
      } else if (that.form3.type == 1) {
        that.cronValue =
          "0 " + that.form3.beginMinute + " " + that.form3.beginHour + " * * *";
        that.$emit("cronCreate", {
          cronValue: that.cronValue,
          perType: 3,
          timeType: 1,
        });
      }
    },
    weekChange: function () {
      that.cronValue =
        "0 " +
        that.form4.beginMinute +
        " " +
        that.form4.beginHour +
        " * * " +
        that.form4.week.toString();
      that.$emit("cronCreate", {
        cronValue: that.cronValue,
        perType: 4,
        timeType: 0,
      });
    },
    dayChange: function () {
      that.cronValue =
        "0 " +
        that.form5.beginMinute +
        " " +
        that.form5.beginHour +
        " " +
        that.form5.day.toString() +
        " * *";
      that.$emit("cronCreate", {
        cronValue: that.cronValue,
        perType: 5,
        timeType: 0,
      });
    },
  },
  beforeMount: function () {
    that = this;
  },
  mounted: function () {
    that.cron.analysis("0/3 * * * * *", 1, 0);
  },
};
</script>

<style scoped>
.el-select,
.el-input,
.el-input-number {
  width: 150px;
}
.iot-tabs-task >>> .el-tabs__nav-wrap::after {
  background-color: rgba(0, 0, 0, 0);
  
}
</style>
