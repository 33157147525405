<template>
  <div class="iot-trigger iot-search-header">
    <el-table
      :data="tableData"
      stripe
      border=""
      style="width: 100%"
      :header-cell-style="{ background: theme.TABLE_HEADER_COLOR }"
    >
      <el-table-column prop="alarmName" label="触发器名称"></el-table-column>
      <el-table-column label="触发器参数" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{
            scope.row.gatewayName +
            "-" +
            scope.row.subOriginalName +
            "-" +
            scope.row.paramName
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop="conditionName"
        label="触发器条件"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="alarmDisType"
        label="当前状态"
        :formatter="alarmDisTypeName"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="forward"
        label="触发方式"
        :formatter="forwardName"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="aInterval"
        label="触发器间隔（分钟）"
      ></el-table-column>
      <!-- <el-table-column label="联动转发">
        <template slot-scope="scope">
          {{ scope.row.forward == 1 ? "联动" : "不联动" }}
        </template>
      </el-table-column> -->
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            plain
            v-show="$store.state.permissionArr.indexOf('alarm:update') > -1"
            @click="editTrigger(scope.row.alarmId)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="danger"
            plain
            v-show="$store.state.permissionArr.indexOf('alarm:delete') > -1"
            @click="del(scope.row.alarmId)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :key="key1"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="getForm.current"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="getForm.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      style="text-align: right; margin-top: 10px; margin-right: 10px"
    ></el-pagination>
    <el-dialog
      class="trigger-dialog"
      :show-close="false"
      :visible.sync="dialogVisinbile"
      :before-close="handleClose"
      :destroy-on-close="true"
      :close-on-press-escape="true"
      :close-on-click-modal="false"
      append-to-body
      top="3%"
      width="50%"
    >
      <div slot="title" style="">
        <img
          style="width: 25px; vertical-align: middle; margin-top: -5px"
          src="../assets/pencil.png"
        />
        <div style="margin-left: 7px; padding-top: 15px; display: inline-block">
          {{ formTitle }}
        </div>
      </div>
      <el-form
        style="100%"
        :model="triggerForm"
        ref="triggerRef"
        label-width="140px"
        :rules="rules"
      >
        <el-form-item label="触发器名称" prop="alarmName">
          <el-input
            v-model="triggerForm.alarmName"
            placeholder="请输入触发器名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="参数源" prop="paramSource">
          <el-radio-group readonly v-model="triggerForm.paramSource">
            <el-radio :label="0" @change="radiochange">设备参数</el-radio>
            <el-radio :label="1" @change="radiochange">数据应用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="选择参数" prop="gatewayId">
          <!-- <el-tooltip placement="right">
            <div slot="content">{{ selectname }}</div>
            <div style="width: 250px"> -->
              <el-cascader
                v-if="gatewayArr.length != 0"
                class="lzlinks-cascader"
                style="width: 250px"
                :props="props"
                :options="gatewayArr"
                @change="cascaderChange"
                v-model="selectList"
                placeholder="请选择参数"
              ></el-cascader>
            <!-- </div>
          </el-tooltip> -->
        </el-form-item>

        <el-form-item label="触发条件" prop="conditionId">
          <el-select
            v-model="triggerForm.conditionId"
            filterable
            placeholder="请选择触发条件"
          >
            <el-option
              v-for="item in conditionArr"
              :key="item.conditionId"
              :label="item.conditionName"
              :value="item.conditionId"
              @click.native="conditionChange(item)"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="conditionItem.aType != 0">
          <div class="condition" style="display: flex; justify-content: left">
            <el-form-item
              label="A"
              prop="aValue"
              label-width="50px"
              v-if="conditionItem.aType != 0"
            >
              <el-cascader
                v-if="conditionItem.aType == 2"
                class="lzlinks-cascader"
                style="width: 250px"
                :props="props2"
                :options="originalArra"
                @change="cascaderChange2"
                v-model="selectList2"
                placeholder="请选择参数"
              ></el-cascader>
              <el-input-number
                v-else-if="conditionItem.aType == 1"
                v-model="triggerForm.aValue"
                placeholder="请输入A"
              ></el-input-number>
            </el-form-item>
            <el-col :span="10" v-if="conditionItem.aAddSubt">
              <el-form-item
                label="X"
                prop="xValue"
                hide-required-asterisk
                label-width="60px"
                required
              >
                <el-input-number
                  v-model="triggerForm.xValue"
                  :min="conditionItem.aAddSubt == 'in' ? 0 : -999999999"
                  placeholder="请输入X"
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-form-item
              label="选择参数"
              status-icon
              hide-required-asterisk
              v-if="triggerForm.conditionId == 9"
            >
              <el-cascader
              clearable
                v-if="gatewayArr.length != 0"
                class="lzlinks-cascader"
                style="width: 250px"
                :props="props3"
                :options="originalArrs"
                @change="cascaderChange3"
                v-model="selectList3"
                placeholder="请选择参数"
              ></el-cascader>
            </el-form-item>
          </div>
        </el-form-item>

        <el-form-item label="是否启用" prop="forward">
          <el-radio-group readonly v-model="triggerForm.alarmDisType">
            <el-radio :label="0">启用</el-radio>
            <el-radio :label="1">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="触发方式" prop="forward">
          <el-select
            v-model="triggerForm.forward"
            filterable
            placeholder="请选择触发方式"
          >
            <el-option label="报警" :value="0"> </el-option>
            <el-option label="联动" :value="2"> </el-option>
            <el-option label="报警+联动" :value="1"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item class="forward">
          <el-form-item
            v-if="triggerForm.forward != 0"
            label="联动参数"
            prop="alarmForward.gatewayId"
          >
            <el-cascader
              v-if="gatewayArr.length != 0"
              placeholder="请选择参数"
              class="lzlinks-cascader"
              style="width: 250px"
              :props="props1"
              :options="gatewayArr1"
              @change="cascaderChange1"
              v-model="selectList1"
            ></el-cascader>
          </el-form-item>
          <el-form-item
            v-if="triggerForm.forward != 0"
            label="下发数据"
            prop="alarmForward.sendData"
          >
            <el-input-number
              controls-position="right"
              v-model="triggerForm.alarmForward.sendData"
              placeholder="请输入下发数据"
              :min="-999999999"
              :max="999999999"
            ></el-input-number>
          </el-form-item>
          <el-form-item
            label="触发器间隔（分钟）"
            v-if="triggerForm.forward != 2"
            prop="aInterval"
          >
            <el-input-number
              controls-position="right"
              size="small"
              v-model="triggerForm.aInterval"
              placeholder="请输入触发器间隔"
              :min="0"
              :max="1440"
              :precision="0"
            ></el-input-number>
          </el-form-item>

          <el-form-item
            label="解决方案"
            v-if="triggerForm.forward != 2"
            prop="solutionId"
          >
            <el-select
              v-model="triggerForm.solutionId"
              filterable
              placeholder="请选择解决方案"
            >
              <el-option
                v-for="item in solutionArr"
                :key="item.solutionId"
                :label="item.solutionName"
                :value="item.solutionId"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain size="mini" @click="submitForm"
          >确 定</el-button
        >
        <el-button
          type="info"
          plain
          size="mini"
          @click="dialogVisinbile = false"
          >取 消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { TABLE_HEADER_COLOR } from "../util/theme";
import reg from "../util/regular";
let that;
export default {
  name: "Trigger",
  props: {
    currentPlanId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      key1: 0,
      selectname: "",
      selectname1: "",
      selectname2: "",
      selectname3: "",
      selectList: [],
      selectList1: [],
      selectList2: [],
      selectList3: [],
      props: {
        //下拉联动
        lazy: true,
        value: "value",
        label: "label",
        multiple: false,
      lazyLoad(node, resolve) {
    
      if (node.level == 1) {
        that.changeGateway(node.data.value);
      } else if (node.level == 2) {
        if (that.triggerForm.paramSource == 0) {
          that.getParamArr(node.data.value, true);
        } else {
          that.getdataApplication(node.data.value);
        }
      }

      // if (node.level != 0) {
        setTimeout(() => {
          let nodes = [];
          if (node.level == 1) {
            nodes = that.originalArr;
          } else if (node.level == 2) {
            if (that.triggerForm.paramSource == 0) {
              nodes = that.paramArr;
            } else {
              nodes = that.application;
            }
          }
          if (node.children.length>0) {
            node.children=[];
          }
         resolve(nodes);
          // 通过调用resolve将子节点数据返回，通知组件数据加载完成
          
        }, 1000);
      // }
    },
      },
      props1: {
        //下拉联动
        lazy: true,
        value: "value",
        label: "label",
        multiple: false,
        lazyLoad(node, resolve) {
          // console.log(node, resolve);
          //   const { level } = node;
          if (node.level == 1) {
            that.changeGateway1(node.data.value);
          } else if (node.level == 2) {
            that.getParamArr(node.data.value, false);
          }
          setTimeout(() => {
            let nodes = [];
            if (node.level == 1) {
              nodes = that.originalArr1;
            } else if (node.level == 2) {
              nodes = that.paramArr1;
            }      
            if (node.children.length>0) {
            node.children=[];
          }
            // 通过调用resolve将子节点数据返回，通知组件数据加载完成
            resolve(nodes);
          }, 1000);
        },
      },
      props2: {
        //下拉联动
        lazy: true,
        value: "value",
        label: "label",
        multiple: false,
        lazyLoad(node, resolve) {
          // console.log(node, resolve);
          //   const { level } = node;
          if (node.level == 1) {
            that.changeAOriginal(node.data.value);
          }
          setTimeout(() => {
            let nodes = [];
            if (node.level == 1) {
              nodes = that.paramArr2;
            }
            if (node.children.length>0) {
            node.children=[];
          }
            // 通过调用resolve将子节点数据返回，通知组件数据加载完成
            resolve(nodes);
          }, 1000);
        },
      },
      props3: {
        //下拉联动
        lazy: true,
        value: "value",
        label: "label",
        multiple: false,
        lazyLoad(node, resolve) {
          // console.log(node, resolve);
          //   const { level } = node;
          if (node.level == 1) {
            that.changeSOriginal(node.data.value);
          }
          setTimeout(() => {
            let nodes = [];
            if (node.level == 1) {
              nodes = that.paramArr3;
            }
            if (node?.children.length>0) {
            node.children=[];
          }
            // 通过调用resolve将子节点数据返回，通知组件数据加载完成
            resolve(nodes);
          }, 1000);
        },
      },

      subchange: false,
      flagID: "",
      theme: { TABLE_HEADER_COLOR },
      dialogVisinbile: false,
      tableData: [],
      radios: 0,
      getForm: {
        triggerName: "",
        size: 10,
        current: 1,
        planId: "",
      },
      total: 0,
      triggerForm: {
        sValue: "",
        aSubOriginalId: "",
        alarmDisType:1,
        alarmId: "",
        alarmName: "",
        planId: "",
        gatewayId: "",
        subOriginalId: "",
        paramId: "",
        conditionId: "",
        aInterval: 0,
        aValue: "",
        xValue: "",
        solutionId: "",
        alarmRank: "",
        forward: 0,
        paramSource: 0,

        alarmForward: {
          gatewayId: "",
          subOriginalId: "",
          paramId: "",
          sendData: 0,
        },
      },
      permissionForm: {},
      formTitle: "",
      isCreate: true,
      settingData: {
        triggerId: "",
        triggerName: "",
        menuIds: [],
      },
      defaultProps: {
        label: "menuName",
        children: "children",
      },
      gatewayArr: [],
      gatewayArr1: [],
      originalArr: [],
      originalArra: [],
      originalArrs: [],
      paramArr: [],
      conditionArr: [],
      solutionArr: [],
      originalArr1: [],
      paramArr1: [],
      paramArr2: [],
      paramArr3: [],
      application: [],
      conditionItem: {
        aType: 0,
        aAddSubt: "",
      },
      rules: {
        alarmName: [
          { required: true, message: "请输入触发器名称", trigger: "blur" },
          {
            pattern: reg.nameR,
            message: "最长支持15个字符",
          },
        ],
        gatewayId: [{ required: true, message: "请选择参数", trigger: "blur" }],
        paramSource: [
          { required: true, message: "请选择参数源", trigger: "blur" },
        ],
        forward: [
          { required: true, message: "请选择触发器方式", trigger: "blur" },
        ],
        subOriginalId: [
          { required: true, message: "请选择设备", trigger: "blur" },
        ],
        paramId: [{ required: true, message: "请选择参数", trigger: "blur" }],

        conditionId: [
          { required: true, message: "请选择触发条件", trigger: "blur" },
        ],
        aValue: [{ required: true, message: "请选择阈值A", trigger: "blur" }],
        bValue: [{ required: true, message: "请选择阈值B", trigger: "blur" }],
        "alarmForward.gatewayId": [
          {
            validator: (rule, value, callback) => {
              if (
                that.triggerForm.forward == 1 &&
                that.triggerForm.alarmForward.gatewayId == ""
              ) {
                callback(new Error("请选择转发网关"));
              }
              callback();
            },
            trigger: "blur",
          },
        ],
        "alarmForward.subOriginalId": [
          {
            validator: (rule, value, callback) => {
              if (
                that.triggerForm.forward == 1 &&
                that.triggerForm.alarmForward.subOriginalId == ""
              ) {
                callback(new Error("请选择转发设备"));
              }
              callback();
            },
            trigger: "blur",
          },
        ],
        "alarmForward.paramId": [
          {
            validator: (rule, value, callback) => {
              if (
                that.triggerForm.forward == 1 &&
                that.triggerForm.alarmForward.paramId == ""
              ) {
                callback(new Error("请选择转发参数"));
              }
              callback();
            },
            trigger: "blur",
          },
        ],
        "alarmForward.sendData": [
          {
            validator: (rule, value, callback) => {
              if (
                that.triggerForm.forward == 1 &&
                (that.triggerForm.alarmForward.sendData == "" ||
                  that.triggerForm.alarmForward.sendData == undefined)
              ) {
                callback(new Error("请选择转发值"));
              }
              callback();
            },
            trigger: "blur",
          },
        ],
      },
    };
  },

  methods: {
  

    // getSelectname(val){
    // let gateway;
    //    that.gatewayArr.forEach((item) => {
    //     if (item.value == val.gatewayId) {
    //       gateway = item.label;
    //     }
    //   });
    //   let sub;
    //   that.originalArr.forEach((item) => {
    //     if (item.value == val.subOriginalId) {
    //       sub = item.label;
    //     }
    //   });
    //   let param;
    //   if (that.triggerForm.paramSource == 0) {
    //     that.paramArr.forEach((item) => {
    //       if (item.value == val.paramId) {
    //         param = item.label;
    //       }
    //     });
    //   } else {
    //     that.application.forEach((item) => {
    //       if (item.value == val.paramId) {
    //         param = item.label;
    //       }
    //     });
    //   }
    //   that.selectname = gateway + "/" + sub + "/" + param;

    // },


    cascaderChange: function (val) {
      that.$set(that.triggerForm, "gatewayId", val[0]);
      that.$set(that.triggerForm, "subOriginalId", val[1]);
      that.$set(that.triggerForm, "paramId", val[2]);
      // that.getSelectname(that.triggerForm)
      that.getConditionArr();
    },
    cascaderChange1: function (val) {
      that.$set(that.triggerForm.alarmForward, "gatewayId", val[0]);
      that.$set(that.triggerForm.alarmForward, "subOriginalId", val[1]);
      that.$set(that.triggerForm.alarmForward, "paramId", val[2]);
    },
    cascaderChange2: function (val) {
      that.$set(that.triggerForm, "aSubOriginalId", val[0]);
      that.$set(that.triggerForm, "aValue", val[1]);
    },
    cascaderChange3: function (val) {
      if (val.length) {
        that.$set(that.triggerForm, "sSubOriginalId", val[0]);
        that.$set(that.triggerForm, "sValue", val[1]);
      } else {
        that.$set(that.triggerForm, "sSubOriginalId",0);
        that.$set(that.triggerForm, "sValue", 0);
     }
     console.log(that.triggerForm);
    },

    handleSizeChange(val) {
      that.$set(that.getForm, "size", val);
      that.$set(that.getForm, "current", 1);
      that.getTriggerPage();
    },
    handleCurrentChange(val) {
      that.$set(that.getForm, "current", val);
      that.getTriggerPage();
    },
    getTriggerPage() {
      that.$api.plan.getTriggerPage(that.getForm).then((response) => {
        that.tableData = response.data.records;
        that.total = response.data.total;
      });
    },
    addTrigger() {
      that.gatewayArr = [];
      that.selectList = [];
      that.conditionArr = [];
      that.selectList1 = [];
      that.selectList2 = [];
      that.selectList3 = [];
      that.getGatewayArr();
      that.getGatewayArr1();
      that.dialogVisinbile = true;
      that.isCreate = true;
      that.formTitle = "新增触发器";
      that.selectname1 = "请选择参数";
      that.selectname2 = "请选择参数";
      that.selectname3 = "请选择参数";
      that.selectname = "请选择参数";

      (that.triggerForm = {
        sValue: "",
        aSubOriginalId: "",
        sSubOriginalId: "",
        alarmDisType:1,
        alarmId: "",
        alarmName: "",
        planId: that.currentPlanId,
        gatewayId: "",
        subOriginalId: "",
        paramId: "",
        conditionId: "",
        aInterval: 0,
        aValue: "",
        xValue: "",
        solutionId: "",
        alarmRank: "",
        forward: 0,
        paramSource: 0,

        alarmForward: {
          gatewayId: "",
          subOriginalId: "",
          paramId: "",
          sendData: 0,
        },
      }),
        (that.subchange = false);
      (that.application = {}), that.getFormData();
    },
    handleClose() {
      that.dialogVisinbile = false;
    },
    editTrigger(id) {
      that.formTitle = "修改触发器";
      new Promise((resolve) => {
        that.$api.plan.getTrigger(id).then((response) => {
          if (response.code == 200) {
            resolve(response.data);
          }
        });
      }).then((res) => {
        that.triggerForm = Object.assign({}, that.triggerForm, res);
        if (that.triggerForm.solutionId == 0) {
          that.triggerForm.solutionId = "";
        }
        that.selectList = [];
        that.selectList1 = [];
        that.selectList2 = [];
        that.selectList3 = [];
        that.selectname=res.gatewayName+'/'+res.subOriginalName+'/'+res.paramName
        that.getConditionArr();
        
        that.editGatewayArr(res);
        that.selectList = [res.gatewayId * 1, res.subOriginalId * 1, res.paramId * 1];
        if (res.aSubOriginalId != 0) {
          that.editOriginalArra(res);
          that.selectList2 = [res.aSubOriginalId*1, res.aValue*1];
        } else {
          that.$api.plan.getOriginalArr(res.gatewayId).then((response) => {
            if (response.code == 200) {
              let newOpts = [];
              response.data.forEach((element) => {
                newOpts.push({
                  value: element.subOriginalId,
                  label: element.subOriginalName,
                });
              });
              that.originalArra = newOpts;
            }
          });
        }
        if (res.sSubOriginalId != 0) {
          that.editOriginalArrs(res);
          that.selectList3 = [res.sSubOriginalId*1, res.sValue*1];
        } else {
          that.$api.plan.getOriginalArr(res.gatewayId).then((response) => {
            if (response.code == 200) {
              let newOpts = [];
              response.data.forEach((element) => {
                newOpts.push({
                  value: element.subOriginalId,
                  label: element.subOriginalName,
                });
              });
              that.originalArrs = newOpts;
            }
          });
        }
        if (res.forward != 0) {
          that.editGatewayArr1(res.alarmForward);
          that.selectList1 = [
            res.alarmForward.gatewayId*1,
            res.alarmForward.subOriginalId*1,
            res.alarmForward.paramId*1,
          ];
        } else {
          that.$api.plan.getGatewayArr(that.currentPlanId).then((response) => {
            if (response.code == 200) {
              let newOpts = [];
              response.data.forEach((element) => {
                newOpts.push({
                  value: element.gatewayId,
                  label: element.gatewayName,
                });
              });
              that.gatewayArr1 = newOpts;
            }
          });
        }
        that.conditionItem = res.ctionTable;
        that.dialogVisinbile = true;
        that.isCreate = false;
        that.getFormData();
      });
    },

    editGatewayArr(v) {
      that.$api.plan.getGatewayArr(that.currentPlanId).then((response) => {
        if (response.code == 200) {
          let newOpts = [];
          response.data.forEach((element) => {
            newOpts.push({
              value: element.gatewayId,
              label: element.gatewayName,
            });
          });

          that.$api.plan.getOriginalArr(v.gatewayId).then((res) => {
            if (res.code == 200) {
              let newOpts1 = [];
              res.data.forEach((element) => {
                newOpts1.push({
                  value: element.subOriginalId,
                  label: element.subOriginalName,
                });
              });
              if (that.triggerForm.paramSource == 0) {
                that.$api.parameter.getParamArr(v.subOriginalId).then((r) => {
                  if (r.code == 200) {
                    that.paramArr2 = [];
                    let newOpts2 = [];
                    r.data.forEach((element) => {
                      newOpts2.push({
                        value: element.paramId,
                        label: element.paramName,
                        leaf: true,
                      });
                    });

                    for (let i = 0; i < newOpts1.length; i++) {
                      if (newOpts1[i].value == v.subOriginalId) {
                        that.$set(newOpts1[i], "children", newOpts2);
                      }
                    }
                    for (let i = 0; i < newOpts.length; i++) {
                      if (newOpts[i].value == v.gatewayId) {
                        that.$set(newOpts[i], "children", newOpts1);
                      }
                    }

                    that.gatewayArr = newOpts;
                  }
                });
              } else {
                that.$api.dataApplication
                  .getProtocolList(v)
                  .then((r) => {
                    that.paramArr2 = [];
                    if (r.code == 200) {
                      let newOpts2 = [];
                      r.data.forEach((element) => {
                        newOpts2.push({
                          value: element.paramId,
                          label: element.paramName,
                          leaf: true,
                        });
                      });
                      for (let i = 0; i < newOpts1.length; i++) {
                        if (newOpts1[i].value == v.subOriginalId) {
                          that.$set(newOpts1[i], "children", newOpts2);
                        }
                      }
                      for (let i = 0; i < newOpts.length; i++) {
                        if (newOpts[i].value == v.gatewayId) {
                          that.$set(newOpts[i], "children", newOpts1);
                        }
                      }

                      that.gatewayArr = newOpts;
                    }
                  });
              }
            }
          });
        }
      });
    },
    editGatewayArr1(v) {
      that.$api.plan.getGatewayArr(that.currentPlanId).then((response) => {
        if (response.code == 200) {
          let newOpts = [];
          response.data.forEach((element) => {
            newOpts.push({
              value: element.gatewayId,
              label: element.gatewayName,
            });
          });

          that.$api.plan.getOriginalArr(v.gatewayId).then((response) => {
            if (response.code == 200) {
              let newOpts1 = [];
              response.data.forEach((element) => {
                newOpts1.push({
                  value: element.subOriginalId,
                  label: element.subOriginalName,
                });
              });

              that.$api.parameter.getParamArr(v.subOriginalId).then((res) => {
                if (res.code == 200) {
                  that.paramArr2 = {};
                  let newOpts2 = [];
                  res.data.forEach((element) => {
                    newOpts2.push({
                      value: element.paramId,
                      label: element.paramName,
                      leaf: true,
                    });
                  });

                  for (let i = 0; i < newOpts1.length; i++) {
                    if (newOpts1[i].value == v.subOriginalId) {
                      that.$set(newOpts1[i], "children", newOpts2);
                    }
                  }
                  for (let i = 0; i < newOpts.length; i++) {
                    if (newOpts[i].value == v.gatewayId) {
                      that.$set(newOpts[i], "children", newOpts1);
                    }
                  }
                  that.gatewayArr1 = newOpts;
                }
              });
            }
          });
        }
      });
    },
    editOriginalArra(v) {
      that.$api.plan.getOriginalArr(v.gatewayId).then((response) => {
        if (response.code == 200) {
          // that.originalArra = [];
          let newOpts = [];
          response.data.forEach((element) => {
            newOpts.push({
              value: element.subOriginalId,
              label: element.subOriginalName,
            });
          });

          // that.originalArra = newOpts;
          that.$api.parameter.getParamArr(v.aSubOriginalId).then((res) => {
            if (res.code == 200) {
              that.paramArr2 = [];
              let newOpts1 = [];
              res.data.forEach((element) => {
                newOpts1.push({
                  value: element.paramId,
                  label: element.paramName,
                  leaf: true,
                });
              });

              for (let i = 0; i < newOpts.length; i++) {
                if (newOpts[i].value == v.aSubOriginalId) {
                  that.$set(newOpts[i], "children", newOpts1);
                }
              }
              that.originalArra = newOpts;
            }
          });
        }
      });
    },
    editOriginalArrs(v) {
      that.$api.plan.getOriginalArr(v.gatewayId).then((response) => {
        that.originalArrs = [];
        if (response.code == 200) {
          let newOpts = [];
          response.data.forEach((element) => {
            newOpts.push({
              value: element.subOriginalId,
              label: element.subOriginalName,
            });
          });

          // that.originalArra = newOpts;
          that.$api.parameter.getParamArr(v.sSubOriginalId).then((res) => {
            if (res.code == 200) {
              let newOpts1 = [];
              res.data.forEach((element) => {
                newOpts1.push({
                  value: element.paramId,
                  label: element.paramName,
                  leaf: true,
                });
              });

              for (let i = 0; i < newOpts.length; i++) {
                if (newOpts[i].value == v.sSubOriginalId) {
                  that.$set(newOpts[i], "children", newOpts1);
                }
              }
              that.originalArrs = newOpts;
            }
          });
        }
      });
    },
    submitForm() {
      that.$refs["triggerRef"].validate((valid) => {
        if (valid) {
          let _api = that.isCreate
            ? that.$api.plan.addTrigger(that.triggerForm)
            : that.$api.plan.editTrigger(that.triggerForm);
          _api.then((response) => {
            if (response.code == 200) {
              that.getTriggerPage();
              that.dialogVisinbile = false;
              that.isCreate = true;
              that.$refs.triggerRef.resetFields();
              that.$message({
                type: "success",
                message: "请进行远程更新项目！",
                showClose: true,
                center: true,
              });
              // that.$message({
              //   type: "success",
              //   message: response.msg,
              //   showClose: true,
              //    center: true,
              // });
            }
          });
        }
      });
    },
    del(triggerId) {
      that
        .$confirm("确认删除", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that.$api.plan.delTrigger(triggerId).then((response) => {
            if (response.code == 200) {
              that.$message({
                type: "success",
                message: response.msg,
              });
              that.getTriggerPage();
            }
          });
        })
        .catch(() => {});
    },
    getGatewayArr() {
      that.$api.plan.getGatewayArr(that.currentPlanId).then((response) => {
        if (response.code == 200) {
          let newOpts = [];
          response.data.forEach((element) => {
            newOpts.push({
              value: element.gatewayId,
              label: element.gatewayName,
            });
          });
          that.gatewayArr = newOpts;
        }
      });
    },
    getGatewayArr1() {
      that.$api.plan.getGatewayArr(that.currentPlanId).then((response) => {
        if (response.code == 200) {
          let newOpts = [];
          response.data.forEach((element) => {
            newOpts.push({
              value: element.gatewayId,
              label: element.gatewayName,
            });
          });
          that.gatewayArr1 = newOpts;
        }
      });
    },
    getOriginalArr(v, bool) {
      that.$api.plan.getOriginalArr(v).then((response) => {
        if (response.code == 200) {
          if (bool) {
            let newOpts = [];
            response.data.forEach((element) => {
              newOpts.push({
                value: element.subOriginalId,
                label: element.subOriginalName,
              });
            });
            that.originalArr = newOpts;
            that.originalArra = newOpts;
            that.originalArrs = newOpts;
          } else {
            let newOpts = [];
            response.data.forEach((element) => {
              newOpts.push({
                value: element.subOriginalId,
                label: element.subOriginalName,
              });
            });
            that.originalArr1 = newOpts;
          }
        }
      });
    },
    getParamArr(v, bool) {
      that.$api.parameter.getParamArr(v).then((response) => {
        if (response.code == 200) {
          if (bool) {
            that.paramArr = {};
            let newOpts = [];
            response.data.forEach((element) => {
              newOpts.push({
                value: element.paramId,
                label: element.paramName,
                leaf: true,
              });
            });
            that.paramArr = newOpts;
          } else {
            that.paramArr1 = {};
            let newOpts = [];
            response.data.forEach((element) => {
              newOpts.push({
                value: element.paramId,
                label: element.paramName,
                leaf: true,
              });
            });
            that.paramArr1 = newOpts;
          }
        }
      });
    },
    getConditionArr() {
      that.$api.plan
        .getConditionArr(that.triggerForm.paramSource)
        .then((response) => {
          if (response.code == 200) {
            that.conditionArr = response.data;
          }
        });
    },
    getSolutionArr() {
      that.$api.alarmSolution.getlistAlarmSolution(that.currentPlanId).then((response) => {
        if (response.code == 200) {
          that.solutionArr = response.data;
        }
      });
    },
    getFormData() {
      that.getSolutionArr();
    },
    changeGateway(v) {
      that.getOriginalArr(v, true);
    },
    changeOriginal(v) {
      that.subchange = true;
      that.flagID = v;
      that.getParamArr(v, true);
      that.getConditionArr();
    },
    changeAOriginal(v) {
      that.$api.parameter.getParamArr(v).then((response) => {
        if (response.code == 200) {
          that.paramArr2 = {};
          let newOpts = [];
          response.data.forEach((element) => {
            newOpts.push({
              value: element.paramId,
              label: element.paramName,
              leaf: true,
            });
          });
          that.paramArr2 = newOpts;
        }
      });
    },
    changeSOriginal(v) {
      that.$api.parameter.getParamArr(v).then((response) => {
        if (response.code == 200) {
          that.paramArr3 = {};
          let newOpts = [];
          response.data.forEach((element) => {
            newOpts.push({
              value: element.paramId,
              label: element.paramName,
              leaf: true,
            });
          });
          that.paramArr3 = newOpts;
        }
      });
    },
    changeGateway1(v) {
      that.getOriginalArr(v, false);
    },
    changeOriginal1(v) {
      that.getParamArr(v, false);
    },
    conditionChange(item) {
      that.conditionItem = item;
    },
    radiochange() {
      that.selectList = [];
      that.getGatewayArr();
    },
    getdataApplication(v) {
      let data={
        "planId":that.currentPlanId,
        "subOriginalId":v,
      }
      that.$api.dataApplication.getProtocolList(data).then((response) => {
        if (response.code == 200) {
          let newOpts = [];
          response.data.forEach((element) => {
            newOpts.push({
              value: element.paramId,
              label: element.paramName,
              leaf: true,
            });
          });
          that.application = newOpts;
        }
      });
    },
    forwardName(row) {
      // console.log(row.forward);
      switch (row.forward) {
        case 0:
          return "报警";
        case 1:
          return "报警+联动";
        case 2:
          return "联动";

        default:
          break;
      }
    },
    alarmDisTypeName(row) {
      switch (row.alarmDisType) {
        case 0:
          return "启用";
        case 1:
          return "禁用";
        default:
          break;
      }
    },
  },
  beforeCreate() {
    that = this;
  },
  mounted() {
    that.$set(that.getForm, "planId", that.currentPlanId);
    that.$set(that.triggerForm, "planId", that.currentPlanId);
    that.getTriggerPage();
  },
};
</script>

<style scoped>
.el-select,
.el-input,
.el-input-number {
  width: 250px;
}
.el-input-number >>> input {
  text-align: left;
}
.iot-trigger {
  width: 96%;
  padding: 0 2%;
}
.iot-trigger-create {
  float: right;
  margin: 10px;
}
.iot-trigger-permission {
  height: 387px;
}
.iot-search-header >>> .el-card__header {
  padding: 0 18px;
}

.trigger-dialog >>> .el-dialog__body {
  height: 485px;
  overflow-y: auto;
}
.trigger-dialog >>> .el-dialog__body::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.trigger-dialog >>> .el-dialog {
  background-color: #f5f7fa;
  height: 775px;
}
.trigger-dialog >>> .el-tabs__nav-wrap::after {
  background-color: rgba(0, 0, 0, 0);
}
.trigger-title {
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 800;
}
.trigger-but {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog-footer {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
::v-deep .el-input__inner::placeholder {
  /* color: rgba(0, 0, 0, 0.685) !important; */
}
::v-deep .el-form-item {
  margin-bottom: 10px !important;
}
::v-deep .condition .el-form-item {
  margin-bottom: 0px !important;
}
::v-deep .el-form-item__label {
  font-weight: 1000;
}

::v-deep .forward .el-form-item__label {
  text-align: left !important;
}
</style>