<template>
  <div class="iot-alarmSolution">
    <div class="iot-alarmSolution-title">
      <el-form :inline="true" :model="pageFrom"  class="demo-form-inline">
        <el-form-item label="方案名称" >
          <el-input
            size="mini"
            v-model="pageFrom.solutionName"
            placeholder="方案名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            plain
            size="mini"
            @click="getPageAlarmSolution"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item style="float: right; margin-right: 50px">
          <el-button
            type="primary"
            plain
            size="mini"
            @click="add"
            v-if="$store.state.permissionArr.indexOf('solution:add') > -1"
            >新增</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-dialog
      :before-close="beforeClose"
      :visible.sync="dialogVisible"
      top="3%"
      append-to-body
      width="60%"
    >
      <div>
        <el-form
          :model="alarmSolutionFrom"
          ref="ruleForm"
          label-width="100px"
          :rules="rules"
          class="demo-ruleForm"
        >
          <el-form-item label="方案名" prop="name">
            <el-input v-model="alarmSolutionFrom.solutionName"></el-input>
          </el-form-item>
          <el-form-item label="方案内容">
            <div style="width: 100%; height: 500px">
              <richText
                v-if="dialogVisible"
                :contents="alarmSolutionFrom.content"
                @changeContent="changeContent"
                @changeDel="changeDel"
                @changeAllArr="changeAllArr"
              />
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" plain @click="alarmSolution"
          >确 定</el-button
        >
        <el-button size="mini" type="info" plain @click="delAll"
          >取 消</el-button
        >
      </span>
    </el-dialog>
    <div style="height: 500px; overflow-y: auto">
      <el-table :data="tableData"  style="width: 100%">
        <el-table-column fixed prop="solutionName" label="方案名称" width="240">
        </el-table-column>
        <el-table-column fixed prop="createTime" label="创建时间">
        </el-table-column>
        <el-table-column label="操作" width="240">
          <template slot-scope="scope">
            <el-button
              v-if="$store.state.permissionArr.indexOf('solution:update') > -1"
              @click="getAlarmSolution(scope.row.solutionId)"
              type="primary"
              size="mini"
              >修改</el-button
            >
            <el-button
              v-if="$store.state.permissionArr.indexOf('solution:delete') > -1"
              type="danger"
              @click="delAlarmSolution(scope.row.solutionId)"
              size="mini"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageFrom.current"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageFrom.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageFrom.total"
        style="text-align: right; margin-top: 10px; margin-right: 10px"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import richText from "./RichText.vue";
let that;
export default {
  props: ["planId"],
  components: { richText },
  data() {
    return {
      allArr: [],
      delArr: [],
      startContent: "",
      tableData: [],
      dialogVisible: false,
      alarmSolutionFrom: {
        solutionName: "",
        content: "",
        planId: "",
      },
      alarmSolutionList: [],
      pageFrom: {
        size: 10,
        current: 1,
        solutionName: "",
        total: 0,
        planId: "",
      },
      rules: {
          name: [
            { required: true, message: '请输入方案名称', trigger: 'blur' },
            { min: 1, max: 12, message: '长度在 1 到 12 个字符', trigger: 'blur' }
          ],
          },
    };
  },
  mounted() {
    that = this;
    that.pageFrom.planId = that.planId;
    that.alarmSolutionFrom.planId = that.planId;
    that.getPageAlarmSolution();

  },
  methods: {
    add(){
       that.alarmSolutionFrom.solutionName=""
       that.alarmSolutionFrom.content=""
       that.dialogVisible = true;
    },
    beforeClose(done) {
      this.$confirm("确认关闭？关闭不会保存当前数据;")
        .then(() => {
          done();
          that.delAll();
        })
        .catch(() => {});
    },
    handleSizeChange(val) {
      that.$set(that.pageFrom, "size", val);
      that.$set(that.pageFrom, "current", 1);
      that.getPageAlarmSolution();
    },
    handleCurrentChange(val) {
      that.$set(that.pageFrom, "current", val);
      that.getPageAlarmSolution();
    },
    changeContent(value) {
      that.alarmSolutionFrom.content = `${value}`;
    },
    changeDel(value) {
      that.delArr = value;
    },
    getPageAlarmSolution() {
      that.$api.alarmSolution
        .getPageAlarmSolution(that.pageFrom)
        .then((res) => {
          if (res.code == 200) {
            that.tableData = res.data.records;
            that.pageFrom.total = res.data.total;
          }
        });
    },
    // 修改或添加方案
    alarmSolution() {
      let api = that.alarmSolutionFrom.solutionId
        ? that.$api.alarmSolution.putAlarmSolution(that.alarmSolutionFrom)
        : that.$api.alarmSolution.alarmSolution(that.alarmSolutionFrom);
      api.then((res) => {
        if (res.code == 200) {
          that.imgDel();
          that.getPageAlarmSolution();
          that.dialogVisible = false;
        }
      });
    },
    // 获取解决方案详情
    getAlarmSolution(Id) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that.$api.alarmSolution.getAlarmSolution(Id).then((res) => {
        if (res.code == 200) {
          that.startContent = res.data.content;
          that.alarmSolutionFrom = res.data;
          loading.close();
          that.dialogVisible = true;
        }
      });
    },
    delAlarmSolution(id) {
      that.$api.alarmSolution.delAlarmSolution(id).then((res) => {
        if (res.code == 200) {
          that.getPageAlarmSolution();
        }
      });
    },
    changeAllArr(value) {
      that.allArr = value;
    },
    delAll() {
      that.dialogVisible = false;
      let startArr = [];
      this.startContent.replace(
        /<img[^>]*src=['"]([^'"]+)[^>]/g,
        (match, capture) => {
          startArr.push(capture);
        }
      );
      let arr = [];
      that.allArr.forEach((element) => {
        let flag = true;
        startArr.forEach((item) => {

          if (item == element) {
            flag = false;
          }
        });
        if (flag) {
          arr.push(element);
        }
      });
      that.delArr = arr;
   
      that.imgDel();
    },
    imgDel() {

      that.$api.alarmSolution.imgDel(that.delArr).then((res) => {
        if (res.code == 200) {
          that.getPageAlarmSolution();
        }
      });
    },
  },
};
</script>
<style scoped>
.iot-alarmSolution {
  width: 100%;
  box-sizing: border-box;
}

</style>