<template>
  <div class="iot-select" ref="selectRef">
     <el-checkbox-group style="display:flex;flex-wrap: wrap;" v-model="selectArr">
    <div
      class="check-item"
      v-for="item in checkData"
      :key="item.gatewayId"
      @click="itemClick(item)"
      :class="check(item.gatewayId) ? 'check-active' : ''"
    >
      <el-image
        style="height: 30px; width: 30px; margin: 5px 10px 5px 5px"
        :src="constant.OSS_URL + constant.GATEWAY_IMG + item.gatewayImg"
      ></el-image>
      <span
        style="
          display: inline-block;
          height: 40px;
          width: calc(100% - 70px);
          line-height: 40px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        "
        >{{ item.gatewayName }}</span
      >
      <span style=" display: inline-block; position: relative;">
        <div   style="position: absolute;top:0;left:0;width:100%;height:100%;z-index:100;"></div>
        <el-checkbox :label="item.gatewayId" :key="item.gatewayId"><span></span></el-checkbox>
      </span>
    </div>
    </el-checkbox-group>
  </div>
</template>
<script>
let that;
import constant from "../util/constant";
export default {
  name: "selectComponent",
  props: ["checkType", "checkData", "gatewayIds", "editData"],
  data: function () {
    return {
      constant: constant,
      selectArr: [],
    };
  },
  methods: {
    ceshi(a){
      console.log(a);
    },

    check: function (id) {
      let state = false;
      that.selectArr.forEach((element) => {
        if (element == id) {
          state = true;
        }
      });

      return state;
    },
    itemClick: function (item) {
     
      if (that.checkType == "checkbox") {
        let isRepeat = false;
        that.selectArr.forEach((element, i) => {
          if (element == item.gatewayId) {
            that.selectArr.splice(i, 1);
            isRepeat = true;
            return;
          }
        });

        if (isRepeat) return;
        that.selectArr.push(item.gatewayId);
      } else {
        
            that.selectArr = [item.gatewayId];
         
      }

      that.$emit("selectChange", {
        checkType: that.checkType,
        data: that.checkType == "checkbox" ? that.selectArr : that.selectArr[0],
      });
    },
  },
  beforeMount: function () {
    that = this;
  },
  mounted: function () {
    
    if (that.editData) {
      if (that.editData.gatewayIds || that.editData.gatewayId) {
        if (that.checkType == "checkbox") {
          that.selectArr = that.editData.gatewayIds;
        } else {
          that.selectArr = [that.editData.gatewayId];
        }
      }
    }
  },
};
</script>
<style scoped>
.iot-select {
  width: 100%;
  
}
.check-item {
  width: 22%;
  height: 40px;
  border: 1px solid #cecece;
  color: #cecece;
  border-radius: 3px;
  display: inline-block;
  margin-left: 1.5%;
  margin-top: 10px;
  font-size: 10px;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.check-item:hover,
.check-active {
  border: 1px solid #409eff;
  color: #409eff;
}
::v-deep .el-checkbox__label{
  width: 0 ;
  padding: 0;
  padding-right: 1px !important;
 
}
</style>
